import { Container, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab } from '@mui/material';
import { AppShortcutOutlined, Facebook, FavoriteBorder, History, Instagram, LinkOutlined, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toReal } from '../functions/utils';
import { useState, useEffect } from 'react';
import * as React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';


function Home({ onChange, changeConfigs, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { windows } = props;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [hasOrders, setHasOrders] = useState(true);
  const [isLogged, setIsLogged] = useState(true);
  const [nota, setNota] = useState("");
  const [open, setOpen] = useState(false);
  const [tipoLoja, setTipoLoja] = useState("");
  const [nome, setNome] = useState("");
  const [tempoEntrega, setTempoEntrega] = useState("");
  const [mainId, setMainId] = useState("");
  const [allFunctionsExecuted, setAllFunctionsExecuted] = useState("");
  const [background, setBackground] = useState("");
  const [urlBase, setUrlBase] = useState("");
  const [items, setItems] = useState(null);
  const [pedido, setPedido] = useState(null);
  const [destaques, setDestaque] = useState(null);
  const [favoritos, setFavoritos] = useState(null);
  const [statusEstabelecimento, setStatusEstabelecimento] = useState("");
  const [deferredPrompt, setDeferredPrompt] = useState();
  const [enabledInstall, setEnabledInstall] = useState();

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handlePerfilLoja = () => {
    navigate('/perfil-loja', { replace: true, state: { page: "home", colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } });
  };

  const handleSelectItem = (id, event) => {
    Cookies.set('iIts', id, { expires: 7 });
    onChange(event, "cardapio");
  };

  const handleCardapio = (event) => {
    onChange(event, "cardapio");
  };

  const handlePerfil = (event) => {
    onChange(event, "perfil");
  };

  const handleGetTipoLoja = () => {
    switch (tipoLoja) {
      case "restaurante":
        return <>cardápio</>
      case "loja":
        return <>catálogo</>
    }
  };

  const container = windows !== undefined ? () => windows().document.body : undefined;

  const handleBackButton = (event) => {
    console.log("VOLTA")
  };

  useEffect(() => {
    // handleOpenLoading();

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event)
    };

    window.addEventListener('popstate', handleBackButton);

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    const userIsLogged = Cookies.get('isLogged');
    let urlBase = window.location.href;
    if(urlBase.includes("?")) {
      let arrUrl = urlBase.split("?");
      urlBase = arrUrl[0];
    }

    if (urlBase.includes("itS=")) {
      const arrUrl = urlBase.split("itS=");
      if (!(Cookies.get("pS")) || (!isNaN(Cookies.get("pS")) && Cookies.get("pS") !== arrUrl[1])) {
        Cookies.set('iIts', arrUrl[1], { expires: 7 });
        Cookies.set('pS', arrUrl[1], { expires: 5 / (24 * 60) });
        onChange("", "cardapio");
        return
      }
    }

    if (urlBase.includes("localhost")) {
      urlBase = "https://dedicado.mely.online/";
      // urlBase = "https://acarajedoj.mely.online/"
      // urlBase = "https://rea.mely.online/"
    }

    const disableButtonInstall = Cookies.get('DsIApp') === "true";
    setEnabledInstall(disableButtonInstall);

    if (userIsLogged) {
      const id = Cookies.get('iUsr');
      if (!id) {
        clearCookies()
      } else {
        getUltimoPedido(urlBase, id);
        setIsLogged(true);
      }
    } else {
      setPedido([])
    }

    setFavoritos(Cookies.get('fvUsr'));

    setUrlBase(urlBase);
    getConfig(urlBase);
    getProdutos(urlBase);

    handleCloseLoading();

    setAllFunctionsExecuted(true);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };

  }, [favoritos]);

  const isMesaRedirect = async () => {
    const urlBase = window.location.href
    if (urlBase.includes("nm=")) {
      const arrUrl = urlBase.split("nm=");
      changeConfigs(true);
      Cookies.set('nM', atob(arrUrl[1]), { expires: 7 });
    } else {
      Cookies.remove("nM")
    }
  }

  const clearCookies = () => {
    setIsLogged(false)
    Cookies.remove('isLogged');
    Cookies.remove('emUsr');
    Cookies.remove('pUsr');
    Cookies.remove('tUsr');
    Cookies.remove('iUsr');
    Cookies.remove('nmUsr');
  }

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Usuário aceitou instalar o aplicativo');
          } else {
            console.log('Usuário cancelou a instalação do aplicativo');
          }
          deferredPrompt = null;
        })
        .catch((error) => {
          console.error('Erro: ', error);
        });
    }
  };

  const handleNInstall = () => {
    setEnabledInstall(true);
    Cookies.set('DsIApp', true, { expires: 7 });
  };

  const handleShare = () => {
    setIsShare(true);
    setOpen(true);
  };

  const share = async (id) => {
    let url;

    switch (id) {
      case 1:
        url = `https://www.messenger.com/new/?uri=${urlBase.replaceAll("/api", "")}`
        window.open(url, '_blank');
        break;
      case 2:
        url = `https://api.whatsapp.com/send?text=${urlBase.replaceAll("/api", "")}`
        window.open(url, '_blank');
        break;
      case 3:
        url = `instagram://share?media=${urlBase.replaceAll("/api", "")}&caption=Veja%20só%20isso`
        window.open(url, '_blank');
        break;
      case 4:
        url = `https://telegram.me/share/url?url=${urlBase.replaceAll("/api", "")}&text=Veja%20só%20isso`
        window.open(url, '_blank');
        break;
      case 5:
        url = `${urlBase.replaceAll("/api", "")}`
        try {
          await navigator.clipboard.writeText(url);
          handleSnack("Link copiado com sucesso.")
        } catch (err) {
          handleSnack("Não foi possivel copiar o link")
        }
        break;
    }
  }

  const getInfos = (url, mainId) => {
    axios.post(`${url}api/avaliacoes.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        const avaliacoes = response.data
        let notaTotal = 0, countNotas = 0
        if (avaliacoes) {
          for (const avaliacao of avaliacoes) {
            const partes = avaliacao.avaliacao.split(" -- ");
            notaTotal += parseInt(partes[0])
            countNotas++
          }
          notaTotal = notaTotal / countNotas
          notaTotal = notaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
          setNota(notaTotal)
          Cookies.set('MnT', notaTotal, { expires: 7 });
          return
        }
        setNota("4,5");
        Cookies.set('MnT', "4,5", { expires: 7 });
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getUltimoPedido = (url, idusuarios) => {
    axios.get(`${url}api/ultimo-pedido-usuario.php?idusuarios=${idusuarios}`)
      .then(response => {
        if (response.data) {
          setPedido(response.data)
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getProdutos = (url) => {
    axios.get(`${url}api/produtos-menu.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        let responseOrigin = response.data
        response = response.data
        // verifica o valor a partir
        for (const produto of response) {
          let valorapartir = [], valorapartiratualizado = parseFloat(produto.valor.replace(".", "").replace(",", '.')), ultsubcategoria = "", categoria = produto.categoria, count = -1
          for (const produto of response) {
            if (produto.status == "P") {
              continue;
            }
            if (produto.estoque != undefined && produto.estoque == 0) {
              continue;
            }
            if (produto.tipo != null) {
              if (produto.tipo.includes("adicionais") && produto.tipo.includes("true") && (produto.categoria.includes(produto.nome) || produto.categoria == categoria) && (ultsubcategoria == "" || ultsubcategoria != produto.subcategoria)) {
                count++
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
                ultsubcategoria = produto.subcategoria
              }
              if (produto.tipo.includes("adicionais") && produto.tipo.includes("true") && (produto.categoria.includes(produto.nome) || produto.categoria == categoria) && parseFloat(produto.valor.replace(".", "").replace(",", ".")) < valorapartir[count]) {
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
              }
              if (produto.tipo.includes("opcionais") && produto.tipo.includes("true") && (produto.categoria.includes(produto.nome) || produto.categoria == categoria) && (ultsubcategoria == "" || ultsubcategoria != produto.subcategoria)) {
                count++
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
                ultsubcategoria = produto.subcategoria
              }
              if (produto.tipo.includes("opcionais") && produto.tipo.includes("true") && (produto.categoria.includes(produto.nome) || produto.categoria == categoria) && parseFloat(produto.valor.replace(".", "").replace(",", ".")) < valorapartir[count]) {
                valorapartir[count] = parseFloat(produto.valor.replace(".", "").replace(",", "."))
              }
            }
          }
          for (const valora of valorapartir) {
            valorapartiratualizado += valora
          }
          produto.valor_apartir = valorapartiratualizado
        }

        // ajusta as categorias
        let ultimaCategoria = "", ultimaSubcategoria = ""
        for (const produto of response) {
          const partes = produto.categoria.split(' - ');
          const categoriaNumero = partes[0];
          const categoriaOriginal = partes[1];
          const categoriaProduto = partes[2];
          produto.categoriaNumero = categoriaNumero
          produto.categoriaOriginal = categoriaOriginal
          produto.categoriaProduto = categoriaProduto
          produto.subcategoriaOriginal = produto.subcategoria
          if (ultimaCategoria == "" || ultimaCategoria != produto.categoria) {
            if (!produto.tipo) {
              if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                ultimaCategoria = produto.categoria
                ultimaSubcategoria = produto.subcategoria
                produto.isFirst = "true"
                continue
              } else {
                if (produto.subcategoria == "") {
                  ultimaSubcategoria = produto.subcategoria
                }
                ultimaCategoria = produto.categoria
                produto.isFirst = "true"
                continue
              }
            } else {
              if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
                ultimaCategoria = produto.categoria
                ultimaSubcategoria = produto.subcategoria
                produto.isFirst = "true"
                continue
              }
            }
          } else {
            if (produto.subcategoria != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.subcategoria)) {
              ultimaCategoria = produto.categoria
              ultimaSubcategoria = produto.subcategoria
              produto.isFirst = "true"
              continue
            }
          }
          produto.categoria = ""
          produto.subcategoria = ""
        }
        setDestaque(response.filter(item => item.destaque === 'S'));
        favoritos ? setItems(response.filter(item => favoritos.includes(`"${item.idprodutos}"`))) : setItems([])
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getConfig = (url) => {
    axios.get(`${url}api/get-conf.php`, {})
      .then(response => {
        const arrConf = response.data.split(`\n`)
        for (const variable of arrConf) {
          if (variable.includes("mainName")) {
            const mainName = variable.replace("const mainName = ", "").replaceAll("\"", "")
            setNome(mainName)
            document.title = mainName;
            Cookies.set('Mn', mainName, { expires: 7 });
          }
          if (variable.includes("timeDelivery")) {
            const timeDelivery = variable.replace("const timeDelivery = ", "").replaceAll("\"", "")
            const arrTime = timeDelivery.split("(")
            setTempoEntrega(arrTime[1].replaceAll(")", ""))
            Cookies.set('tD', timeDelivery, { expires: 7 });
            isMesaRedirect();
          }
          if (variable.includes("mainId")) {
            const mainId = variable.replace("const mainId = ", "")
            setMainId(mainId)
            getInfos(url, mainId)
            getHorarios(url, mainId);
            Cookies.set('Md', mainId, { expires: 7 });
          }
          if (variable.includes("urlBase")) {
            const urlBase = variable.replace("const urlBase = ", "").replaceAll("\"", "")
            setUrlBase(urlBase)
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
            Cookies.set('UBs', urlBase, { expires: 7 });
          }
          if (variable.includes("urlMessage")) {
            const urlMessage = variable.replace("const urlMessage = ", "").replaceAll("\"", "").replaceAll(" // wa.me = whatsapp ------ m.me = messenger", "")
            Cookies.set('UMs', urlMessage, { expires: 7 });
          }
          if (variable.includes("imgBannerPromo")) {
            const bannerPromo = variable.replace("const imgBannerPromo = ", "").replaceAll("\"", "")
            if (bannerPromo === "customimg") {
              setOpen(true)
            }
          }
          if (variable.includes("valorMinimo")) {
            const valorMinimo = variable.replace("const valorMinimo = ", "").replaceAll("\"", "")
            Cookies.set('vlMnm', valorMinimo, { expires: 7 });
          }
          if (variable.includes("enableStore")) {
            const enableStore = variable.replace("const enableStore = ", "")
            Cookies.set('eNbS', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("enableForBuy")) {
            const enableStore = variable.replace("const enableForBuy = ", "")
            Cookies.set('eNbB', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("onlyMenu")) {
            const enableStore = variable.replace("const onlyMenu = ", "")
            Cookies.set('eNbM', enableStore === "true", { expires: 7 });
            changeConfigs(false, enableStore === "true");
          }
          if (variable.includes("const imgBanner = ")) {
            const background = variable.replace("const imgBanner = ", "").replaceAll("\"", "").replaceAll("-banner", "")
            console.log(background)
            setBackground(background);
          }
          if (variable.includes("const typeStore")) {
            const tipoLoja = variable.replace("const typeStore = ", "").replaceAll("\"", "")
            Cookies.set('tpLj', tipoLoja, { expires: 7 });
            setTipoLoja(tipoLoja);
            changeConfigs(false, false, tipoLoja);
          }
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorarios = (url, mainId) => {
    axios.post(`${url}api/horarios.php`, {
      idestabelecimentos: mainId,
    })
      .then(response => {
        response = response.data
        const horariosArray = response[0].horarios.split(",");
        const d = new Date();
        let diasemana
        if (d.getDay() == 0) {
          diasemana = horariosArray[6]
        } else {
          diasemana = horariosArray[d.getDay() - 1]
        }
        if (diasemana == "Fechado") {
          Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b>`, { expires: 7 });
          setStatusEstabelecimento(`<b style="color:#A41D1D">Fechado</b>`)
        } else {
          var unix = Math.round(+new Date() / 1000);
          const horarios = diasemana.split(" às ");
          const horario1 = horarios[0].split(":");
          var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
          const horario2 = horarios[1].split(":");
          var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);

          if (parseInt(horario2[0]) < parseInt(horario1[0]) || (horarios[0] == "00:00" && horarios[1] == "00:00")) {
            unix2 += 86400
          }

          const arrTime = Cookies.get("tD").split("(")
          const tempo = arrTime[1].replaceAll(")", "")

          if (unix > unix1 && unix < unix2) {
            Cookies.set('sTA', `<b style="color:#33540E">Aberto</b> até ${horarios[1]} • ${tempo}`, { expires: 7 });

            setStatusEstabelecimento(`<b style="color:#33540E">Aberto</b> até ${horarios[1]} • ${tempo}`)
          } else {
            Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b> abre às ${horarios[0]}`, { expires: 7 });
            setStatusEstabelecimento(`<b style="color:#A41D1D">Fechado</b> abre às ${horarios[0]}`)
          }
        }

      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handleCardFavoritos = (produto) => {
    return <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }} onClick={() => handleSelectItem(produto.idprodutos)}>
      <CardActionArea>
        <CardMedia component="img" height="170" image={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.idprodutos}_0.png`} onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
        }}
        />
        <CardContent style={{ textAlign: "left" }}>
          <Typography gutterBottom style={{ fontSize: "14px", color: "#1B1B1B", marginBottom: "0px", fontWeight: "bold" }} component="div">
            {produto.nome}
          </Typography>
          {produto.valor_desconto && (
            <Typography style={{ fontSize: '12px', color: 'green', fontWeight: "bold" }} component="div">
              <b style={{ textDecoration: "line-through", color: "#1B1B1B" }}>R$ {toReal(parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", ".")))}</b> &nbsp; R$ {toReal(parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(produto.valor_desconto.replaceAll(".", "").replaceAll(",", ".")))}
            </Typography>
          )}

          {toReal(produto.valor_apartir) !== produto.valor && produto.valor_apartir !== 0 && (
            <Typography style={{ fontSize: '12px', color: `rgba(${colorPrimary})`, fontWeight: "bold" }} component="div">
              <b style={{ color: "#1B1B1B", fontWeight: "600" }}>A partir de</b> R$ {toReal(parseFloat(produto.valor_apartir))}
            </Typography>
          )}

          {produto.tipovalor === "Por pessoa" && produto.valor_apartir !== 0 && (
            <Typography style={{ fontSize: '12px', color: `rgba(${colorPrimary})`, fontWeight: "bold" }} component="div">
              R$ {toReal(parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", ".")))} <b style={{ color: "#1B1B1B" }}>Por pessoa</b>
            </Typography>
          )}

          {!produto.valor_desconto && toReal(produto.valor_apartir) === produto.valor && produto.tipovalor !== "Por pessoa" && (
            <Typography style={{ fontSize: '12px', color: `rgba(${colorPrimary})`, fontWeight: "bold" }} component="div">
              R$ {toReal(parseFloat(produto.valor.replaceAll(".", "").replaceAll(",", ".")))}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  }

  return (
    <Container>

      <CardMedia component="img" height="120" image={`https://dedicado.mely.online/imagens/${background}`} style={{ position: "absolute", top: "0", left: "0", right: "0" }} onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "https://dedicado.mely.online/imagens/background.png";
      }} />

      <Fab size="small" variant="circular" color="secondary" aria-label="add" style={{ height: "38px", width: "38px", position: "absolute", right: "10px", top: "10px" }} onClick={handleShare}>
        <ShareOutlined style={{ fontSize: "22px" }} />
      </Fab>

      <LoadingComponent open={loading} />

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msgSnack}
      />

      <div style={{ height: "130px" }}></div>

      {/* Cabeçalho Restaurante */}

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ height: "100%", width: "70px", borderRadius: "4px" }}>
          <Avatar src={`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`} style={{ margin: "auto", width: "70px", height: "70px", fontSize: "15px" }} />

          <div style={{ height: "2px" }}></div>

        </div>
        <div style={{ flexGrow: 1 }}>
          <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
            {/* {nota ? (
              <Chip icon={<Star style={{ color: "#FFF", fontSize: "15px", marginTop: "-3px" }} />} label={nota} size="small" style={{ fontWeight: "normal", backgroundColor: "#33540E", color: "#FFF", borderRadius: "4px", fontSize: "11px", paddingTop: "2px" }} />
            ) : (
              <Skeleton animation="wave" height={40} width="45px" />
            )} */}
            {nome ? (
              <Typography style={{ paddingLeft: "10px", fontWeight: "700" }}>{nome}
              </Typography>
            ) : (
              <>
                &nbsp;&nbsp; <Skeleton animation="wave" height={40} width="155px" />
              </>
            )}
          </Typography>
          {statusEstabelecimento ? (
            <>
              <div style={{ height: "2px" }}></div>
              <Typography style={{ paddingLeft: "10px", fontSize: "11px", color: "#999" }} dangerouslySetInnerHTML={{ __html: statusEstabelecimento }} />
            </>
          ) : (
            <Skeleton animation="wave" height={21} width="100%" />
          )}

          {/* {tempoEntrega ? (
            <>
              <div style={{ height: "10px" }}></div>
              <Chip variant="outlined" icon={<TimerOutlined style={{ color: "#464444", fontSize: "24px", marginRight: "-2px" }} />} label={tempoEntrega} style={{ color: "#464444", borderRadius: "4px", fontSize: "14px", paddingTop: "2px" }} />
            </>
          ) : (
            <Skeleton animation="wave" height={40} width="100px" />
          )} */}
        </div>
        <div style={{ width: "80px", minWidth: "80px" }}>
          <Button variant="contained" size="small" style={{ fontSize: "10px", fontWeight: "bold" }} onClick={handlePerfilLoja}>
            Ver perfil
          </Button>
        </div>
      </div>

      {deferredPrompt && !enabledInstall && (
        <div style={{ background: "#F2F2F2", padding: "10px", borderRadius: "8px", display: "flex", alignItems: "center" }}>
          <Typography style={{ fontWeight: "bold", fontSize: "14px", paddingLeft: "5px", flexGrow: 1 }}>Instalar o aplicativo?</Typography>
          <Button variant="outlined" onClick={handleNInstall} style={{ fontWeight: "bold", fontSize: "13px", textTransform: "capitalize" }}>Não</Button>
          <div style={{ width: "10px" }}></div>
          <Button variant="contained" onClick={handleInstall} style={{ fontWeight: "bold", fontSize: "13px", textTransform: "capitalize" }}>Instalar</Button>
          <div style={{ height: "14px" }}></div>
        </div>
      )}

      <div style={{ height: "10px" }}></div>

      {!isLogged && !hasOrders && (
        <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
          <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

            <img src="https://dedicado.mely.online/imagens/no-order.png" />

            <div style={{ height: "10px" }}></div>

            Você precisa estar logado para ver os seus pedidos. <br />Que tal realizar o login ou cadastrar-se agora?

            <div style={{ height: "20px" }}></div>

            <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handlePerfil}>Ir para o login</Button>
          </Typography>
        </Typography>
      )}

      {isLogged && !hasOrders && (
        <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
          <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

            <img src="https://dedicado.mely.online/imagens/no-order.png" />

            <div style={{ height: "10px" }}></div>

            Você ainda não fez nenhum pedido. <br />Que tal fazer o primeiro agora?

            <div style={{ height: "20px" }}></div>

            <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handleCardapio}>Ir para o {handleGetTipoLoja()}</Button>
          </Typography>
        </Typography>
      )}

      {hasOrders && (
        <>
          {/* Favoritos */}

          {favoritos && (
            <>
              <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                <FavoriteBorder style={{ color: "#1B1B1B", fontSize: "18px" }} />
                &nbsp; Favoritos
              </Typography>

              <div style={{ height: "12px" }}></div>
              {items ? (
                <Carousel centerMode={true} animationHandler={'slide'} showIndicators={false} showArrows={false} showStatus={false} showThumbs={false}>
                  {items.map((produto) => (
                    handleCardFavoritos(produto)
                  ))}
                </Carousel>
              ) : (
                <Carousel centerMode={true} animationHandler={'slide'} showIndicators={false} showArrows={false} showStatus={false} showThumbs={false}>
                  <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
                    <CardActionArea>
                      <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>

                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
                    <CardActionArea>
                      <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>

                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
                    <CardActionArea>
                      <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>

                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Carousel>
              )}
            </>
          )}

          {!favoritos && (
            <>

              {destaques ? (
                <>
                  {destaques.length > 0 && (
                    <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                      <FavoriteBorder style={{ color: "#1B1B1B", fontSize: "18px" }} />
                      &nbsp; Mais pedidos
                    </Typography>
                  )}

                  <div style={{ height: "12px" }}></div>
                  <Carousel centerMode={true} animationHandler={'slide'} showIndicators={false} showArrows={false} showStatus={false} showThumbs={false}>
                    {destaques.map((produto) => (
                      handleCardFavoritos(produto)
                    ))}
                  </Carousel>
                </>
              ) : (
                <Carousel centerMode={true} animationHandler={'slide'} showIndicators={false} showArrows={false} showStatus={false} showThumbs={false}>
                  <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
                    <CardActionArea>
                      <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>

                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
                    <CardActionArea>
                      <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>

                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card variant="outlined" sx={{ maxWidth: 500, marginRight: "16px", borderRadius: "8px" }}>
                    <CardActionArea>
                      <Skeleton animation="rectangular" height={170} width="80%" style={{ marginLeft: "10%" }} />
                      <CardContent style={{ textAlign: "left" }}>
                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>

                        <Typography component="div">
                          <Skeleton animation="wave" height={21} width="100%" />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Carousel>
              )}
            </>
          )}

          <div style={{ height: "24px" }}></div>

          {/* Recentes */}

          {isLogged && pedido ? (
            <>
              {pedido.length !== 0 && (
                <>
                  <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                    <History style={{ color: "#1B1B1B", fontSize: "18px" }} />
                    &nbsp; Recentes
                  </Typography>

                  <div style={{ height: "12px" }}></div>
                  {(JSON.parse(atob(pedido[0].detalhes))).map((detalhes) => (
                    <Card variant="outlined" sx={{ display: 'flex', borderRadius: "8px" }} onClick={() => handleSelectItem(detalhes.id)}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>

                          <Typography component="div" style={{ fontSize: "12px", color: "#1B1B1B", textOverflow: 'ellipsis', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box', alignItems: "center", fontWeight: "bold" }}>
                            {detalhes.nome}
                          </Typography>
                          <Typography style={{ fontSize: '12px', color: '#999', width: '100%', textOverflow: 'ellipsis', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box', maxHeight: '54px' }} component="div" dangerouslySetInnerHTML={{ __html: detalhes.descricao.replaceAll("\n", "<br/>") }}>
                          </Typography>
                        </CardContent>
                      </Box>
                      <p style={{ textAlign: "right", minWidth: "80px", margin: 0, marginBottom: "-5px" }}>
                        <CardMedia component="img" style={{ height: "100%", maxWidth: "140px" }} image={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${detalhes.id}_0.png`} onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                        }}
                        />
                      </p>
                    </Card>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <Typography style={{ fontSize: "16px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                <History style={{ color: "#1B1B1B", fontSize: "18px" }} />
                &nbsp; Recentes
              </Typography>

              <div style={{ height: "12px" }}></div>
              <Card variant="outlined" sx={{ display: 'flex', borderRadius: "8px" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>

                    <Typography component="div" style={{ fontSize: "12px", color: "#1B1B1B", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                      <Skeleton animation="wave" height={21} width="100%" />
                    </Typography>
                    <Typography style={{ fontSize: '12px', color: '#999', width: '100%', textOverflow: 'ellipsis', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box', maxHeight: '54px' }} component="div">
                      <Skeleton animation="wave" height={21} width="100%" />
                    </Typography>
                  </CardContent>
                </Box>
                <p style={{ textAlign: "right", minWidth: "80px", margin: 0, marginBottom: "-5px" }}>
                  <Skeleton animation="rectangular" height={80} width="80%" />
                </p>
              </Card>
            </>
          )}
        </>
      )}

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {!isShare ? (
          <>
            <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

            <img src={`${urlBase.replaceAll("/api", "")}/imagens/promo-banner.png`} style={{ margin: "20px", borderRadius: "8px", maxHeight: "500px" }} />

            <Button variant="outlined" size="large" style={{ textTransform: "capitalize", margin: "20px" }} onClick={toggleDrawer(false)}>Fechar</Button>
          </>
        ) : (
          <>
            <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>
            <Typography style={{ fontSize: "20px", textAlign: "center", fontWeight: "bold" }}>Compartilhe com seus amigos!</Typography>
            <div style={{ height: "20px" }}></div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Chip icon={<WhatsApp onClick={() => share(2)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-3px" }} />} style={{ backgroundColor: "#25D366", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "14px" }} />
              <div style={{ width: "20px" }}></div>
              <Chip icon={<Instagram onClick={() => share(3)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: "#DD2A7B", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "14px" }} />
              <div style={{ width: "20px" }}></div>
              <Chip icon={<Facebook onClick={() => share(1)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: "#3B5998", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "14px" }} />
              <div style={{ width: "20px" }}></div>
              <Chip icon={<Telegram onClick={() => share(4)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: "#6CC1E3", color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "10px" }} />
              <div style={{ width: "20px" }}></div>
              <Chip icon={<LinkOutlined onClick={() => share(5)} style={{ color: "#FFF", fontSize: "30px", marginTop: "-1px" }} />} style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", borderRadius: "50%", height: "50px", width: "50px", paddingLeft: "12px" }} />
            </div>
            <div style={{ height: "40px" }}></div>
          </>
        )}
      </SwipeableDrawer>

      <div style={{ height: "100px" }}></div>

    </Container>
  );
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows: PropTypes.func,
};

export default Home;