import { Container, AppBar, Toolbar, Button, IconButton, Typography, Card, CardContent, Divider, SwipeableDrawer, TextField, Skeleton } from '@mui/material';
import { CheckCircle, Circle, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import '@fontsource/roboto/400.css';
import { traduzirMes } from '../functions/utils';

function Pedidos({ onChange, colorPrimary, colorSecondary, colorTertiary }, props) {

  const { window } = props;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [isMesa, setIsMesa] = useState(false);
  const [pedidos, setPedidos] = useState(null);
  const [isInit, setIsInit] = useState(null);
  const [open, setOpen] = useState(false);
  const [tipoLoja, setTipoLoja] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  const [modalAlert, setModalAlert] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const container = window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();

  const handleOpenModal = (titleModal, descriptionModal) => {
    setTitle(titleModal);
    setDescription(descriptionModal);
    setModalAlert(true);
    handleCloseLoading();
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleCloseModal = () => {
    setModalAlert(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handlePedido = (id) => {
    Cookies.set('idp', id, { expires: 7 });
    navigate("/pedido", { replace: true, state: { page: "pedidos", colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  }

  const handlePedidoAvulso = (id) => {
    Cookies.set('idpT', id, { expires: 7 });
    navigate("/pedido", { replace: true, state: { page: "pedidos", colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  }

  const handleCardapio = (event) => {
    onChange(event, "cardapio");
  };

  const handlePerfil = (event) => {
    onChange(event, "perfil");
  };

  const handleAvulso = () => {
    setOpen(true);
  };

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const tipoLoja = Cookies.get('tpLj');
    setTipoLoja(tipoLoja);
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    const numeroMesa = Cookies.get('nM');
    if (numeroMesa) {
      setIsMesa(true);
      getPedidoMesa(numeroMesa);
      return;
    }

    const userIsLogged = Cookies.get('isLogged');
    if (userIsLogged) {
      setIsLogged(userIsLogged)
      const idusuarios = Cookies.get('iUsr');
      getPedidos(idusuarios);
      return
    }
    setIsInit(true);
    handleCloseLoading();
  }, []);

  const handleGetTipoLoja = () => {
    switch (tipoLoja) {
      case "restaurante":
        return <>cardápio</>
      case "loja":
        return <>catálogo</>
    }
  };

  const handleNumeroPedido = (event) => {
    setNumeroPedido(event.target.value)
  };

  let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
    }
  }

  const getPedidos = (idusuarios) => {
    const urlBase = Cookies.get('UBs');
    axios.get(`${urlBase}/pedidos.php?idusuarios=${idusuarios}`)
      .then(response => {
        if (response.data) {
          setIsInit(true);
          setPedidos(response.data);
          handleCloseLoading();
          return;
        }
        setIsInit(true);
        setPedidos([]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getPedidoMesa = (mesa) => {
    const urlBase = Cookies.get('UBs');
    axios.get(`${urlBase}/pedido-mesa.php?numeromesa=${mesa}`)
      .then(response => {
        console.log(response.data)
        if (!response.data) {
          setPedidos([]);
        }
        if (response.data) {
          setIsInit(true);
          setPedidos(response.data);
          handleCloseLoading();
          return;
        }
        setIsInit(true);
        setPedidos([]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handlePedirNovamente = (pedido) => {
    Cookies.set('crU', pedido.detalhes, { expires: 7 });
    navigate('/carrinho', { replace: true, state: { page: "cardapio", colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } });
  }

  const getDatahora = (data) => {
    if (data) {
      let [dia, mes, a, restante] = data.split(" ");
      mes = traduzirMes(mes);
      return `${dia} ${mes} - ${restante}`;
    }
    return ""
  };

  return (
    <Container>
      {isInit ? (
        <>
          <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
            <Toolbar>
              Pedidos
            </Toolbar>
          </AppBar>

          <LoadingComponent open={loading} />

          <Snackbar
            open={openSnack}
            autoHideDuration={3000}
            onClose={handleClose}
            message={msgSnack}
          />

          <Dialog
            open={modalAlert}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Fechar</Button>
            </DialogActions>
          </Dialog>

          <div style={{ height: "83px" }}></div>


          {isMesa && (
            <>
              {pedidos ? (
                <>
                  {pedidos.length === 0 ? (
                    <>
                      <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
                        <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

                          <img src="https://dedicado.mely.online/imagens/no-order.png" />

                          <div style={{ height: "10px" }}></div>

                          Você ainda não realizou nenhum pedido.

                          <div style={{ height: "20px" }}></div>

                          <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handleCardapio}>Ir para o cardápio</Button>

                          <div style={{ height: "10px" }}></div>

                        </Typography>
                      </Typography>
                    </>
                  ) : (
                    <>
                      {pedidos.map((pedido) => (
                        <>
                          <Typography style={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold" }}>
                            {getDatahora(pedido.datahora)}
                          </Typography>

                          <div style={{ height: "14px" }}></div>

                          <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                            {pedido.status === "F" && (
                              <CardHeader
                                avatar={
                                  <CheckCircle style={{ paddingLeft: "10px" }} />
                                }
                                action={
                                  <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                    <KeyboardArrowRightOutlined />
                                  </IconButton>
                                }
                                title="Pedido concluído"
                                titleTypographyProps={{ fontWeight: "bold" }}
                                style={{ color: "#33540E" }}
                              />
                            )}

                            {pedido.status === "R" && (
                              <CardHeader
                                avatar={
                                  <Circle style={{ paddingLeft: "10px" }} />
                                }
                                action={
                                  <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                    <KeyboardArrowRightOutlined />
                                  </IconButton>
                                }
                                title="Pedido realizado"
                                titleTypographyProps={{ fontWeight: "bold" }}
                                style={{ color: "#1976d2" }}
                              />
                            )}

                            {pedido.status === "P" && (
                              <CardHeader
                                avatar={
                                  <Circle style={{ paddingLeft: "10px" }} />
                                }
                                action={
                                  <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                    <KeyboardArrowRightOutlined />
                                  </IconButton>
                                }
                                title="Pedido em preparo"
                                titleTypographyProps={{ fontWeight: "bold" }}
                                style={{ color: "#1976d2" }}
                              />
                            )}

                            {pedido.status === "E" && (
                              <CardHeader
                                avatar={
                                  <Circle style={{ paddingLeft: "10px" }} />
                                }
                                action={
                                  <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                    <KeyboardArrowRightOutlined />
                                  </IconButton>
                                }
                                title={pedido.endereco === "Retirada" ? "Aguardando retirada" : "Pedido em entrega"}
                                titleTypographyProps={{ fontWeight: "bold" }}
                                style={{ color: "#1976d2" }}
                              />
                            )}

                            <Divider />
                            <CardContent>
                              <Typography style={{ fontSize: "12px", color: "#999" }}>
                                N° {pedido.data}
                              </Typography>
                              <div style={{ height: "10px" }}></div>
                              <Typography style={{ textOverflow: 'ellipsis', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box' }}>
                                {(JSON.parse(atob(pedido.detalhes))).map((detalhes) => (
                                  <>
                                    <Typography style={{ fontSize: "12px", color: "#464444" }}>
                                      {detalhes.quantidade}x {detalhes.nome}
                                    </Typography>
                                  </>
                                ))}
                              </Typography>

                            </CardContent>

                            {pedido.status === "F" && (
                              <CardActions disableSpacing>
                                <Typography style={{ textAlign: "center", width: "100%" }}>
                                  <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={() => handlePedirNovamente(pedido)}> Pedir novamente</Button>
                                </Typography>
                              </CardActions>
                            )}

                            <div style={{ height: "16px" }}></div>
                          </Card>

                          <div style={{ height: "14px" }}></div>
                        </>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography style={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold" }}>
                    <Skeleton animation="wave" height={36} width="240px" />
                  </Typography>

                  <div style={{ height: "14px" }}></div>

                  <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings">
                          <KeyboardArrowRightOutlined />
                        </IconButton>
                      }
                      title={
                        <>
                          <Skeleton animation="wave" height={36} width="240px" />
                        </>
                      }
                      titleTypographyProps={{ fontWeight: "bold" }}
                      style={{ color: "#1976d2" }}
                    />
                    <Divider />
                    <CardContent>
                      <Typography style={{ fontSize: "12px", color: "#999" }}>
                        <Skeleton animation="wave" height={36} width="240px" />
                      </Typography>
                      <div style={{ height: "10px" }}></div>
                      <Typography style={{ textOverflow: 'ellipsis', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box' }}>
                        <Skeleton animation="wave" height={36} width="240px" />
                      </Typography>

                    </CardContent>

                    <div style={{ height: "16px" }}></div>
                  </Card>
                </>
              )}
            </>
          )}

          {!isLogged && !isMesa && (
            <>
              <Typography style={{ textAlign: "center" }}>
                <Button variant="outlined" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handleAvulso}>Buscar pedido</Button>
              </Typography>

              <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

                  <img src="https://dedicado.mely.online/imagens/no-order.png" />

                  <div style={{ height: "10px" }}></div>

                  Você precisa estar logado para ver os seus pedidos. <br />Que tal realizar o login ou cadastrar-se agora?

                  <div style={{ height: "20px" }}></div>

                  <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handlePerfil}>Ir para o login</Button>

                  <div style={{ height: "10px" }}></div>

                </Typography>
              </Typography>
            </>

          )}

          {isLogged && pedidos && (
            <>
              {pedidos.length === 0 && (
                <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
                  <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

                    <img src="https://dedicado.mely.online/imagens/no-order.png" />

                    <div style={{ height: "10px" }}></div>

                    Você ainda não fez nenhum pedido. <br />Que tal fazer o primeiro agora?

                    <div style={{ height: "20px" }}></div>

                    <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handleCardapio}>Ir para o {handleGetTipoLoja()}</Button>
                  </Typography>
                </Typography>
              )}
            </>
          )}


          {isLogged && (
            <>
              {pedidos ? (
                <>
                  {pedidos.map((pedido) => (
                    <>
                      <Typography style={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold" }}>
                        {getDatahora(pedido.datahora)}
                      </Typography>

                      <div style={{ height: "14px" }}></div>

                      <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                        {pedido.status === "F" && (
                          <CardHeader
                            avatar={
                              <CheckCircle style={{ paddingLeft: "10px" }} />
                            }
                            action={
                              <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                <KeyboardArrowRightOutlined />
                              </IconButton>
                            }
                            title="Pedido concluído"
                            titleTypographyProps={{ fontWeight: "bold" }}
                            style={{ color: "#33540E" }}
                          />
                        )}

                        {pedido.status === "R" && (
                          <CardHeader
                            avatar={
                              <Circle style={{ paddingLeft: "10px" }} />
                            }
                            action={
                              <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                <KeyboardArrowRightOutlined />
                              </IconButton>
                            }
                            title="Pedido realizado"
                            titleTypographyProps={{ fontWeight: "bold" }}
                            style={{ color: "#1976d2" }}
                          />
                        )}

                        {pedido.status === "P" && (
                          <CardHeader
                            avatar={
                              <Circle style={{ paddingLeft: "10px" }} />
                            }
                            action={
                              <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                <KeyboardArrowRightOutlined />
                              </IconButton>
                            }
                            title="Pedido em preparo"
                            titleTypographyProps={{ fontWeight: "bold" }}
                            style={{ color: "#1976d2" }}
                          />
                        )}

                        {pedido.status === "E" && (
                          <CardHeader
                            avatar={
                              <Circle style={{ paddingLeft: "10px" }} />
                            }
                            action={
                              <IconButton aria-label="settings" onClick={() => handlePedido(pedido.idpedidos)}>
                                <KeyboardArrowRightOutlined />
                              </IconButton>
                            }
                            title={pedido.endereco === "Retirada" ? "Aguardando retirada" : "Pedido em entrega"}
                            titleTypographyProps={{ fontWeight: "bold" }}
                            style={{ color: "#1976d2" }}
                          />
                        )}

                        <Divider />
                        <CardContent>
                          <Typography style={{ fontSize: "12px", color: "#999" }}>
                            N° {pedido.data}
                          </Typography>
                          <div style={{ height: "10px" }}></div>
                          <Typography style={{ textOverflow: 'ellipsis', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box' }}>
                            {(JSON.parse(atob(pedido.detalhes))).map((detalhes) => (
                              <>
                                <Typography style={{ fontSize: "12px", color: "#464444" }}>
                                  {detalhes.quantidade}x {detalhes.nome}
                                </Typography>
                              </>
                            ))}
                          </Typography>

                        </CardContent>

                        {pedido.status === "F" && (
                          <CardActions disableSpacing>
                            <Typography style={{ textAlign: "center", width: "100%" }}>
                              <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={() => handlePedirNovamente(pedido)}> Pedir novamente</Button>
                            </Typography>
                          </CardActions>
                        )}

                        <div style={{ height: "16px" }}></div>
                      </Card>

                      <div style={{ height: "14px" }}></div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <Typography style={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold" }}>
                    <Skeleton animation="wave" height={36} width="240px" />
                  </Typography>

                  <div style={{ height: "14px" }}></div>

                  <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings">
                          <KeyboardArrowRightOutlined />
                        </IconButton>
                      }
                      title={
                        <>
                          <Skeleton animation="wave" height={36} width="240px" />
                        </>
                      }
                      titleTypographyProps={{ fontWeight: "bold" }}
                      style={{ color: "#1976d2" }}
                    />
                    <Divider />
                    <CardContent>
                      <Typography style={{ fontSize: "12px", color: "#999" }}>
                        <Skeleton animation="wave" height={36} width="240px" />
                      </Typography>
                      <div style={{ height: "10px" }}></div>
                      <Typography style={{ textOverflow: 'ellipsis', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box' }}>
                        <Skeleton animation="wave" height={36} width="240px" />
                      </Typography>

                    </CardContent>

                    <div style={{ height: "16px" }}></div>
                  </Card>
                </>
              )}
            </>
          )}

          <SwipeableDrawer
            container={container}

            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

            <Typography style={{ fontSize: "12px", paddingLeft: "20px", color: "#999", fontWeight: "bold" }}>
              Preencha o número do pedido
            </Typography>
            <div style={{ height: "10px" }}></div>
            <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>
              <TextField
                fullWidth
                hiddenLabel
                value={numeroPedido}
                onChange={handleNumeroPedido}
                variant="outlined"
                placeholder="Número do pedido"
                type="text"
              />
            </Typography>

            <Button variant="contained" size="large" style={{ textTransform: "capitalize", margin: "20px" }} onClick={() => handlePedidoAvulso(numeroPedido)}>Continuar</Button>

          </SwipeableDrawer>

          <div style={{ height: "100px" }}></div>
        </>
      ) : (
        <>

          <Skeleton animation="wave" style={{ position: "absolute", top: "0", left: "0", right: "0", borderRadius: "0", height: "80px", marginTop: "-18px" }} />

          <div style={{ height: "70px" }}></div>

          <Typography style={{ fontSize: "16px", color: "#1B1B1B", fontWeight: "bold" }}>
            <Skeleton animation="wave" height={36} width="240px" />
          </Typography>

          <div style={{ height: "14px" }}></div>

          <Card variant="outlined" sx={{ borderRadius: "8px" }}>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                  <KeyboardArrowRightOutlined />
                </IconButton>
              }
              title={
                <>
                  <Skeleton animation="wave" height={36} width="240px" />
                </>
              }
              titleTypographyProps={{ fontWeight: "bold" }}
              style={{ color: "#1976d2" }}
            />
            <Divider />
            <CardContent>
              <Typography style={{ fontSize: "12px", color: "#999" }}>
                <Skeleton animation="wave" height={36} width="240px" />
              </Typography>
              <div style={{ height: "10px" }}></div>
              <Typography style={{ textOverflow: 'ellipsis', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box' }}>
                <Skeleton animation="wave" height={36} width="240px" />
              </Typography>

            </CardContent>

            <div style={{ height: "16px" }}></div>
          </Card>
        </>
      )
      }
    </Container >
  );
}

export default Pedidos;